import { NestGlobalErrorHandler } from '@nest/common/core/frontend';
import { injectDialogs } from '@nest/uikit/dialogs/ui';
import { ValidationResultDialogComponent, ValidationResultDialogData } from '@nest/uikit/validations/dialogs/ui';

export class DesktopGlobalErrorHandler extends NestGlobalErrorHandler {
	dialogs = injectDialogs();

	override async handleError(error: Error) {
		super.handleError(error);

		let message = error.message;

		if ('items' in error && Array.isArray(error.items)) {
			message = error.items.map(i => i.message).join('\n');
		}

		this.dialogs.open<ValidationResultDialogData>(ValidationResultDialogComponent, {
			data: {
				items: [
					{
						type: 'error',
						message: message
					}
				]
			}
		});
	}
}
