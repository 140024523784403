@if (loaded()) {
	@if (organization()) {
		<router-outlet></router-outlet>
	}
	@if (organization() == null) {
		<div class="selector">
			<div class="content">
				<div class="headline">Výběr organizace</div>
				<lib-select [formControl]="formControl" [filter]="{ isOrganization: true }" [appPartiesAutocomplete]></lib-select>
			</div>
		</div>
	}
}
