import { APP_INITIALIZER, inject } from '@angular/core';
import {
	StockItemDto,
	StockItemEntityName,
	StockItemLocationDto,
	StockItemLocationEntityName,
	StockItemMovementDto,
	StockItemMovementEntityName,
	StorageLocationDto,
	StorageLocationEntityName,
	WarehouseDto,
	WarehouseEntityName,
	WarehouseRelationDto,
	WarehouseRelationEntityName
} from '@nest/domain/entities-metadata/dtos';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

export function provideWmsCoreEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);

				return () => {
					entityMetadata.registerEntity({
						entityName: StockItemMovementEntityName,
						defaultElements: fieldsToElements(
							autoPathsFor<StockItemMovementDto>()([
								'sourceWarehouseLocation.name',
								'sourcePalette.code',
								'destinationWarehouseLocation.name',
								'destinationPalette.code',
								'quantity'
							])
						)
					});
					entityMetadata.registerEntity({
						entityName: StockItemEntityName,
						defaultElements: fieldsToElements(autoPathsFor<StockItemDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: StorageLocationEntityName,
						defaultElements: fieldsToElements(autoPathsFor<StorageLocationDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: WarehouseEntityName,
						defaultElements: fieldsToElements(autoPathsFor<WarehouseDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: StockItemLocationEntityName,
						defaultElements: fieldsToElements(autoPathsFor<StockItemLocationDto>()(['storageLocation.name', 'handlingUnit.code', 'quantity']))
					});
					entityMetadata.registerEntity({
						entityName: WarehouseRelationEntityName,
						defaultElements: fieldsToElements(autoPathsFor<WarehouseRelationDto>()(['type']))
					});
				};
			}
		}
	];
}
