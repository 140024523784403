import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	templateUrl: './dashboard-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class DashboardPageComponent {

}
