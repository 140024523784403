<div class="text-white bg-black flex flex-row items-center gap-2 px-4 min-h-14" ship libLayoutContainerItem="auto">
	<div class="pr-4">
		<svg _ngcontent-ng-c618280517="" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="logo">
			<path
				_ngcontent-ng-c618280517=""
				d="M4.642 9.02V15H0.3V0.179999H4.694L8.62 6.16C9.20933 7.044 9.66 7.78933 9.972 8.396H10.05V5.9V0.179999H14.366V15H9.946L5.916 8.76L4.746 6.992H4.668L4.642 9.02Z"
				fill="currentColor"
			></path>
		</svg>
	</div>

	<div (click)="changeOrganization()" class="rounded-sm bg-slate-700 text-white px-2.5 py-1 text-sm">{{ app.organization()?.name }}</div>

	<button nest variant="ghost" libDropdownTrigger class="hover:bg-slate-300 hover:bg-opacity-20 hover:text-white">
		<!-- <ng-icon name="iconoirKanbanBoard" iconPosition="left"></ng-icon> -->
		Oblíbené

		<ui-dropdown *libDropdown style="min-width: 288px">
			<lib-favorites-menu></lib-favorites-menu>
		</ui-dropdown>
	</button>

	<a nest variant="ghost" class="hover:bg-slate-300 hover:bg-opacity-20 hover:text-white" [routerLink]="['/parties']">
		<!-- <ng-icon name="iconoirBookStack" iconPosition="left"></ng-icon> -->
		Adresář
	</a>

	<button nest variant="ghost" libDropdownTrigger class="hover:bg-slate-300 hover:bg-opacity-20 hover:text-white">
		<!-- <ng-icon name="iconoirKanbanBoard" iconPosition="left"></ng-icon> -->
		ERP

		<ui-dropdown *libDropdown style="width: 288px">
			<a ui-dropdown-item [routerLink]="['/sale-orders']">Objednávky přijaté</a>
			<a ui-dropdown-item [routerLink]="['/purchase-orders']">Objednávky vydané</a>
			<a ui-dropdown-item [routerLink]="['/inbound/documents']">Příjmové doklady</a>
			<a ui-dropdown-item [routerLink]="['/outbound/documents']">Výdejové doklady</a>
			<a ui-dropdown-item [routerLink]="['/invoices/issued']">Faktury vydané</a>
			<a ui-dropdown-item [routerLink]="['/invoices/received']">Faktury přijaté</a>
			<a ui-dropdown-item [routerLink]="['/sale-orders/reservations']">Rezervace zboží</a>
			<a ui-dropdown-item [routerLink]="['/edi/inbound/orders']">Příchozí EDI objednávky</a>
			<a ui-dropdown-item [routerLink]="['/inventory/items']">Zásoby</a>
			<a ui-dropdown-item [routerLink]="['/inventory/movements']">Pohyby zásob</a>
			<a ui-dropdown-item [routerLink]="['/items']">Zboží</a>
			<a ui-dropdown-item [routerLink]="['/pricelists']">Ceníky odběratelské</a>
			<a ui-dropdown-item [routerLink]="['/suppliers/pricelists']">Ceníky dodavatelské</a>
			<a ui-dropdown-item [routerLink]="['/sale-orders/sales-channels']">Prodejní kanály</a>
		</ui-dropdown>
	</button>

	<button nest variant="ghost" libDropdownTrigger class="hover:bg-slate-300 hover:bg-opacity-20 hover:text-white">
		<!-- <ng-icon name="iconoirBox3dPoint" iconPosition="left"></ng-icon> -->
		Sklad

		<ui-dropdown *libDropdown style="width: 288px">
			<a ui-dropdown-item [routerLink]="['/stock-items/movements']">Pohyby na skladě</a>
			<a ui-dropdown-item [routerLink]="['/handling-units/movements']">Pohyby palet</a>
			<a ui-dropdown-item [routerLink]="['/stock-items/locations']">Zboží na lokacích</a>
			<a ui-dropdown-item [routerLink]="['/inbound/delivery/orders']">Objednávky příjmu</a>
			<a ui-dropdown-item [routerLink]="['/outbound/delivery/orders']">Objednávky výdeje</a>
			<!-- <ui-dropdown-item [routerLink]="['/delivery/items']">Zásilky</ui-dropdown-item> -->
			<a ui-dropdown-item [routerLink]="['/delivery/packages/units']">Balíky</a>
			<!-- <a ui-dropdown-item  [routerLink]="['/stock-items']">Skladové karty</a> -->
		</ui-dropdown>
	</button>

	<button nest variant="ghost" libDropdownTrigger class="hover:bg-slate-300 hover:bg-opacity-20 hover:text-white">
		<!-- <ng-icon name="iconoirDeliveryTruck" iconPosition="left"></ng-icon> -->
		Rozvoz

		<ui-dropdown *libDropdown style="width: 288px">
			<a ui-dropdown-item [routerLink]="['/shipments/planner']">Plánovač přepravy zásilek</a>
			<a ui-dropdown-item [routerLink]="['/shipments/summary-check']">Kontrola rozvozu</a>
			<!-- <ui-dropdown-item [routerLink]="['/shipments/routes']">Přehled plánů</ui-dropdown-item> -->
		</ui-dropdown>
	</button>

	<button nest variant="ghost" libDropdownTrigger class="hover:bg-slate-300 hover:bg-opacity-20 hover:text-white">
		<!-- <ng-icon name="iconoirAppWindow" iconPosition="left"></ng-icon> -->
		Aplikace

		<ui-dropdown *libDropdown style="width: 288px">
			<a ui-dropdown-item [routerLink]="['/settings']">Nastavení</a>
			<a ui-dropdown-item [routerLink]="['/jobs']">Úlohy na pozadí</a>
			<a ui-dropdown-item [routerLink]="['/synchronization/items']">Synchronizované záznamy</a>
			<a ui-dropdown-item [routerLink]="['/mails/sent']">Odeslané e-maily</a>
		</ui-dropdown>
	</button>

	<button nest variant="ghost" class="cursor-pointer hover:bg-slate-300 hover:bg-opacity-20 hover:text-white" (click)="openSearch()">Vyhledávání</button>

	<div class="ml-auto flex flex-row gap-8 h-full items-center">
		<div class="flex flex-row items-center h-full" (click)="toggleNotificationPanel()">
			<ng-icon name="iconoirBell" iconPosition="left"></ng-icon>
			@if (notifications.unreadNotificationCount() > 0) {
				<div class="cursor-pointer bg-red-700 text-white rounded-md px-2 py-1 text-sm">{{ notifications.unreadNotificationCount() }}</div>
			}
		</div>

		<div libDropdownTrigger class="flex flex-row items-center h-full">
			<ng-icon name="iconoirUser" iconPosition="left"></ng-icon>
			<div class="cursor-pointer">{{ auth.identity()?.name }}</div>

			<ui-dropdown *libDropdown style="width: 288px">
				<!-- <ui-dropdown-item libDropdownTrigger triggerEventType="hover">
					Změna odsazení aplikace

					<ui-dropdown *libDropdown style="width: 288px">
						<ui-dropdown-label>Odsazení aplikace</ui-dropdown-label>
						@for (size of sizes; track $index) {
							<ui-dropdown-item (click)="changeSizing(size)">
								<span class="semibold" style="font-weight: 600">{{ size.label }}</span>

								@if (size == currentSize()) {
									<ng-icon name="iconoirCheck" class="text-2xl ml-auto"></ng-icon>
								}
							</ui-dropdown-item>
						}
						</ui-dropdown>
						</ui-dropdown-item> -->
				<ui-dropdown-item (click)="logout()">
					<ng-icon name="iconoirLogOut" iconPosition="left"></ng-icon>
					Odhlásit</ui-dropdown-item
				>
			</ui-dropdown>
		</div>
	</div>
</div>

@if (notificationPanelOpen()) {
	<div
		uiFocusArea
		class="animate-in fade-in slide-in-from-right-1/4 fixed top-14 right-0 mobile:w-full w-[400px] h-screen duration-300 ease-in-out bg-white border-l border-gray-200 shadow-lg flex flex-col z-30"
	>
		<lib-notification-panel></lib-notification-panel>
	</div>
}

<div libLayoutContainerItem="stretch">
	<router-outlet></router-outlet>
</div>
