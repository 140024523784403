import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@nest/common/core/frontend';
import { ButtonComponent } from '@nest/uikit/button/ui';
import { TextboxComponent } from '@nest/uikit/textbox/ui';

@Component({
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, TextboxComponent, ButtonComponent]
})
export class LoginPageComponent {
	auth = inject(AuthService);
	router = inject(Router);

	formGroup = new FormGroup({
		username: new FormControl<string>(''),
		password: new FormControl<string>('')
	});

	async onSubmit() {
		let result = await this.auth.login(this.formGroup.getRawValue());

		if (result.token) {
			this.router.navigate(['/']);
		}
	}
}
