import { Routes } from '@angular/router';
import { AlreadyLoggedInGuard, CanActivateApplicationGuard } from '@nest/common/core/frontend';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { OrganizationLayoutComponent } from './layout/main-layout/organization-layout.component';
import { LoginPageComponent } from './pages/auth/login-page/login-page.component';
import { DashboardPageComponent } from './pages/dashboard/dashboard-page.component';

export const routes: Routes = [
	{ path: 'login', component: LoginPageComponent, canActivate: [AlreadyLoggedInGuard] },
	{
		path: '',
		component: MainLayoutComponent,
		canActivate: [CanActivateApplicationGuard],
		children: [
			{ path: 'parties', loadChildren: () => import('./routes/parties.routes').then(m => m.partyRoutes) },
			{ path: 'shipments/planner', loadChildren: () => import('./routes/shipment-planner.routes').then(m => m.shipmentPlannerRoutes) },
			{ path: 'shipments/summary-check', loadChildren: () => import('./routes/shipment-summary-check.routes').then(m => m.shipmentSummaryCheckRoutes) },
			{
				path: '',
				component: OrganizationLayoutComponent,
				children: [
					{ path: '', component: DashboardPageComponent },
					// ERP

					{ path: 'sale-orders', loadChildren: () => import('./routes/sale-orders.routes').then(m => m.saleOrderRoutes) },
					{ path: 'purchase-orders', loadChildren: () => import('./routes/purchase-orders.routes').then(m => m.purchaseOrderRoutes) },
					{ path: 'inbound/documents', loadChildren: () => import('./routes/inbound-documents.routes').then(m => m.inboundDocumentRoutes) },
					{ path: 'outbound/documents', loadChildren: () => import('./routes/outbound-documents.routes').then(m => m.outboundDocumentRoutes) },
					{ path: 'edi/inbound/orders', loadChildren: () => import('./routes/edi-inbound-orders.routes').then(m => m.ediInboundOrderRoutes) },
					{ path: 'inventory', loadChildren: () => import('./routes/inventory-items.routes').then(m => m.inventoryItemsRoutes) },
					{ path: 'items', loadChildren: () => import('./routes/items.routes').then(m => m.itemsRoutes) },
					{ path: 'pricelists', loadChildren: () => import('./routes/pricelists.routes').then(m => m.pricelistsRoutes) },
					{ path: 'suppliers/pricelists', loadChildren: () => import('./routes/supplier-pricelists.routes').then(m => m.supplierPricelistsRoutes) },
					{ path: 'stock-items', loadChildren: () => import('./routes/stock-items.routes').then(m => m.stockItemsRoutes) },
					// { path: 'handling-units', loadChildren: () => import('./routes/handling-units.routes').then(m => m.handlingUnitsRoutes) },
					// { path: 'delivery', loadChildren: () => import('./routes/delivery.routes').then(m => m.deliveryRoutes) },
					{ path: 'outbound/delivery/orders', loadChildren: () => import('./routes/outbound-delivery-orders.routes').then(m => m.outboundDeliveryOrdersRoutes) },
					{ path: 'inbound/delivery/orders', loadChildren: () => import('./routes/inbound-delivery-orders.routes').then(m => m.inboundDeliveryOrdersRoutes) },
					{ path: 'invoices/issued', loadChildren: () => import('./routes/issued-invoices.routes').then(m => m.issuedInvoiceRoutes) },
					{ path: 'invoices/received', loadChildren: () => import('./routes/received-invoices.routes').then(m => m.receivedInvoiceRoutes) }

					// { path: 'purchase-orders', component: PurchaseOrdersPageComponent },
					// { path: 'purchase-orders/lines', component: PurchaseOrderLinesPageComponent },
					// { path: 'purchase-orders/:id', component: PurchaseOrderFormPageComponent, canDeactivate: [CanDeactivateRouteGuard] },

					// { path: 'invoices/received', component: InvoicesPageComponent },
					// { path: 'invoices/received/lines', component: InvoiceLinePagesComponent },
					// { path: 'invoices/received/:id', component: InvoiceFormComponent },

					// { path: 'invoices/received', component: ReceivedInvoicesPageComponent },
					// { path: 'invoices/issued/lines', component: IssuedInvoiceLinesPageComponent },
					// { path: 'invoices/received/lines', component: ReceivedInvoiceLinesPageComponent },
					// { path: 'invoices/issued/:id', component: IssuedInvoiceFormPageComponent, canDeactivate: [CanDeactivateRouteGuard] },
					// { path: 'invoices/received/:id', component: ReceivedInvoiceFormPageComponent, canDeactivate: [CanDeactivateRouteGuard] },

					// { path: 'inventory/items/movements', component: ItemMovementsPageComponent },
					// { path: 'inventory/items/daily', component: InventoryItemsDailyPageComponent },
					// { path: 'inventory/items', component: InventoryItemsPageComponent },

					// { path: 'edi/inbound/orders', component: EdiInboundOrdersPageComponent },

					// { path: 'items/reservations', component: ItemReservationsPageComponent },
					// { path: 'items', component: ItemsPageComponent },
					// { path: 'items/:id', component: ItemFormPageComponent, canDeactivate: [CanDeactivateRouteGuard] },

					// { path: 'outbound/documents', component: OutboundDocumentsPageComponent },
					// { path: 'outbound/documents/lines', component: OutboundDocumentLinesPageComponent },
					// { path: 'outbound/documents/:id', component: OutboundDocumentFormPageComponent, canDeactivate: [CanDeactivateRouteGuard] },

					// { path: 'inbound/documents', component: InboundDocumentsPageComponent },
					// { path: 'inbound/documents/lines', component: InboundDocumentLinesPageComponent },
					// { path: 'inbound/documents/:id', component: InboundDocumentFormPageComponent, canDeactivate: [CanDeactivateRouteGuard] },

					// { path: 'pricelists', component: PricelistsPageComponent },
					// { path: 'pricelists/:pricelistId/items', component: PricelistItemsPageComponent },
					// { path: 'pricelists/items', component: PricelistItemsPageComponent },
					// { path: 'pricelists/:id', component: PricelistFormPageComponent, canDeactivate: [CanDeactivateRouteGuard] },

					// { path: 'suppliers/pricelists', component: SupplierPricelistsPageComponent },
					// { path: 'suppliers/pricelists/:pricelistId/items', component: SupplierPricelistItemsPageComponent },
					// { path: 'suppliers/pricelists/:id', component: SupplierPricelistFormPageComponent, canDeactivate: [CanDeactivateRouteGuard] },
					// { path: 'suppliers/stocks/items', component: SupplierStockItemsPageComponent },

					// { path: 'sales-channels', component: SalesChannelsPageComponent },

					// Sklad
					// { path: 'skus/movements', component: StockItemMovementsPageComponent },
					// { path: 'skus/locations', component: StockItemLocationsPageComponent },

					// { path: 'organizations/pricelists/:pricelistId/items', component: OrganizationPricelistItemsPageComponent },
					// { path: 'organizations/pricelists', component: OrganizationPricelistsPageComponent },
					// { path: 'organizations/pricelists/:id', component: OrganizationPricelistFormComponent },

					// { path: 'palettes/movements', component: HandlingUnitMovementsPageComponent },

					// { path: 'stock-items', component: StockItemsPageComponent },

					// { path: 'inbound/delivery/orders', component: InboundDeliveryOrdersPageComponent },
					// { path: 'inbound/delivery/orders/lines', component: InboundDeliveryOrderLinesPageComponent },
					// { path: 'inbound/delivery/orders/new', component: InboundDeliveryOrderFormPageComponent },
					// { path: 'inbound/delivery/orders/:id', component: InboundDeliveryOrderFormPageComponent, canDeactivate: [CanDeactivateRouteGuard] },

					// { path: 'delivery/items', component: DeliveryItemsPageComponent },
					// { path: 'packages/units', component: PackageUnitsPageComponent },

					// { path: 'stock-takes', component: StockTakesPageComponent },
					// { path: 'stock-takes/items', component: StockTakeItemsPageComponent },
					// { path: 'stock-takes/new', component: StockTakeFormPageComponent },
					// { path: 'stock-takes/:id', component: StockTakeFormPageComponent },

					// { path: 'outbound/delivery/orders', component: OutboundDeliveryOrdersPageComponent },
					// { path: 'outbound/delivery/orders/lines', component: OutboundDeliveryOrderLinesPageComponent },
					// { path: 'outbound/delivery/orders/new', component: OutboundDeliveryOrderFormPageComponent },
					// { path: 'outbound/delivery/orders/:id', component: OutboundDeliveryOrderFormPageComponent, canDeactivate: [CanDeactivateRouteGuard] }
				]
			},
			{
				path: 'settings',
				children: []
			}
			// { path: 'jobs', component: NestJobsPageComponent },
			// { path: 'jobs/:jobId', component: NestJobDetailComponent },
			// { path: 'synchronization/items', component: SynchronizationItemsPageComponent },
			// { path: 'nest/mails/sent', component: NestSentMailsPageComponent }
		]
	}
];
