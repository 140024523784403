import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.env !== 'development') {
	Sentry.init({
		dsn: 'https://a0db03530552b4ecb33db93524dc7e24@sentry.tirextyre.cz/2',
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		tracesSampleRate: 1.0,
		tracePropagationTargets: [environment.apiUrl],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		environment: environment.env
	});
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
